import { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types';

function DetectJavaScript(props) {
    const javaScriptEnabled = true;

    const onSummary = props.onSummary;

    const dataObject = useMemo(() => {
        return {
            javaScriptEnabled: javaScriptEnabled ? 'Yes' : 'No'
        }
    }, [javaScriptEnabled]);

    useEffect(() => {
        onSummary(DetectJavaScript, dataObject);
    }, [onSummary, dataObject]);

   return (
        <div>
            <p className="nogap">
               JavaScript Enabled: {dataObject.javaScriptEnabled}
            </p>
        </div>
    );
}

DetectJavaScript.propTypes = {
    onSummary: PropTypes.func.isRequired
}

export default DetectJavaScript