import { useUA } from "use-ua-parser-js";
import "./App.css";
//import imgCopyPaste from './assets/copyandpaste.png'

import DetectJavaScript from "./components/DetectJavaScript";
import DetectScreenResolution from "./components/DetectScreenResolution";
import DetectPopupBlocker from "./components/DetectPopupBlocker";
import DetectOperatingSystem from "./components/DetectOperatingSystem";
import DetectBrowser from "./components/DetectBrowser";
import CopyToClipboard from "./components/CopyToClipboard";
/*
import GetJsonExport from './components/GetJsonExport'
import GetXmlExport from './components/GetXmlExport'
*/

function App() {
  const summaryDataObject = {
    summary: {},
  };

  const onSummary = (component, dataObject) => {
    const objectName = camelize(component.name);
    summaryDataObject["summary"][objectName] = dataObject;
  };

  const uaDetails = useUA();
  const currentWindow = window;
  const currentNavigator = window.navigator;
  const currentDocument = document;

  const summaryDivId = "summaryDiv";

  return (
    <>
      {/*
                <p className="header">System Check</p>
                <p>
                    <img src={imgCopyPaste} alt="Copy and Paste" />
                </p>
                <p className="header_instr">
                    INSTRUCTIONS
                </p>
                <p>
                    PLEASE COPY THE INFORMATION BELOW THE RED LINE BY HIGHLIGHTING IT AND PRESSING (CTRL + C) THEN PASTE IT BY PRESSING (CTRL + V) back in the CHAT
                    ROOM or if you are responding to an EMAIL or TICKET request - paste into the body of your EMAIL Response.
                </p>
                <p>
                    You may also copy and paste using your mouse cursor. To do this, simply highlight the text then right click and select &quot;Copy&quot;. To paste, right
                    click and select &quot;Paste&quot;
                </p>
     */}
      <div className="header">ESKILLZ SYSTEM CHECK RESULTS</div>
      <div className="modal-window">
        <hr className="redline" />

        <div id={summaryDivId} className="summary">
          <DetectOperatingSystem uaDetails={uaDetails} onSummary={onSummary} />

          <DetectBrowser
            uaDetails={uaDetails}
            onSummary={onSummary}
            currentNavigator={currentNavigator}
          />

          <DetectJavaScript onSummary={onSummary} />

          <DetectScreenResolution
            onSummary={onSummary}
            currentWindow={currentWindow}
          />

          <DetectPopupBlocker
            onSummary={onSummary}
            currentWindow={currentWindow}
          />
        </div>

        <hr className="redline" />
      </div>
      <div className="modal-window instruct">
        <CopyToClipboard
          buttonText="Click here to COPY results"
          summaryDivId={summaryDivId}
          currentDocument={currentDocument}
          currentNavigator={currentNavigator}
          currentWindow={currentWindow}
        />
      </div>
      <div className="footer">
        powered by{" "}
        <img
          id="footerlogo"
          src="https://www.eskillz.com/uploads/9/2/6/3/92632316/eskillz-home-logo.png"
          alt="eSkillz logo"
        />
      </div>
      {/*
                //<hr className="redline" />

                <GetJsonExport buttonText="Click here to get results in JSON format" summaryDataObject={summaryDataObject} currentDocument={currentDocument} />

                <hr className="redline" />

                <GetXmlExport buttonText="Click here to get results in XML format" summaryDataObject={summaryDataObject} currentDocument={currentDocument} />

                <hr className="redline" />
      */}
    </>
  );
}

function camelize(str) {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, "");
}

export default App;
