import { useEffect, useMemo } from "react";
import PropTypes from "prop-types";

function DetectPopupBlocker(props) {
  let popupBlockerEnabled = false;

  const onSummary = props.onSummary;
  const currentWindow = props.currentWindow;

  //only need one of these tests, leaving both for now
  const testWindow = currentWindow.open("", "_blank");
  if (
    !testWindow ||
    testWindow.closed ||
    typeof testWindow.closed == "undefined"
  ) {
    popupBlockerEnabled = true;
  } else {
    testWindow.close();
  }

  const testWindow2 = currentWindow.open("", "_blank");
  try {
    testWindow2.focus();
    testWindow2.close();
  } catch (e) {
    popupBlockerEnabled = true;
  }

  const dataObject = useMemo(() => {
    return {
      popupBlockerEnabled: popupBlockerEnabled ? "Yes" : "No",
    };
  }, [popupBlockerEnabled]);

  useEffect(() => {
    onSummary(DetectPopupBlocker, dataObject);
  }, [onSummary, dataObject]);

  return (
    <div>
      <p className="nogap">
        Popup Blocker Enabled: {dataObject.popupBlockerEnabled}
      </p>
    </div>
  );
}

DetectPopupBlocker.propTypes = {
  onSummary: PropTypes.func.isRequired,
  currentWindow: PropTypes.shape({
    open: PropTypes.func,
  }),
};

export default DetectPopupBlocker;
