import { useEffect, useMemo } from "react";
import PropTypes from "prop-types";

function DetectScreenResolution(props) {
  const currentWindow = props.currentWindow;
  const windowHeight = currentWindow.innerHeight,
    windowWidth = currentWindow.innerWidth,
    screenHeight = currentWindow.screen.height,
    screenWidth = currentWindow.screen.width;

  const onSummary = props.onSummary;

  const dataObject = useMemo(() => {
    return {
      screenResolution: screenWidth + " x " + screenHeight,
      screenResolutionWidth: screenWidth,
      screenResolutionHeight: screenHeight,
      windowDisplaySize: windowWidth + " x " + windowHeight,
      windowDisplaySizeWidth: windowWidth,
      windowDisplaySizeHeight: windowHeight,
    };
  }, [screenWidth, screenHeight, windowWidth, windowHeight]);

  useEffect(() => {
    onSummary(DetectScreenResolution, dataObject);
  }, [onSummary, dataObject]);

  //var text = `Window Height: ${windowHeight}, Window Width: ${windowWidth}, Screen Height: ${screenHeight}, Screen Width: ${screenWidth}`;
  // to keep up to date on window resize: https://codingbeautydev.com/blog/react-get-window-width-height/
  return (
    <div>
      <p className="nogap">
        Screen Resolution (Width x Height): {dataObject.screenResolution}
        <br />
        Window Display Size (Width x Height): {dataObject.windowDisplaySize}
      </p>
    </div>
  );
}

DetectScreenResolution.propTypes = {
  onSummary: PropTypes.func.isRequired,
  currentWindow: PropTypes.shape({
    innerHeight: PropTypes.number,
    innerWidth: PropTypes.number,
    screen: PropTypes.shape({
      height: PropTypes.number,
      width: PropTypes.number,
    }),
  }),
};
export default DetectScreenResolution;
