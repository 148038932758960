import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

/*
https://stackoverflow.com/questions/48846289/why-is-my-react-component-is-rendering-twice
if in StrictMode, and in dev mode, components will render twice by design
*/
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
//    <React.StrictMode>
        <App />
//    </React.StrictMode>
);
