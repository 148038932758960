import { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types';

function DetectOperatingSystem(props) {
    const uaDetails = props.uaDetails;
    const onSummary = props.onSummary;

    const dataObject = useMemo(() => {
        return {
            operatingSystem: uaDetails.os.name + ' ' + uaDetails.os.version,
            operatingSystemName: uaDetails.os.name,
            operatingSystemVersion: uaDetails.os.version
        }
    }, [uaDetails]);

    useEffect(() => {
        onSummary(DetectOperatingSystem, dataObject);
    }, [onSummary, dataObject]);

    return (
        <div>
            <p>
                Operating System: {dataObject.operatingSystem}
            </p>
        </div>
    );
}

DetectOperatingSystem.propTypes = {
    uaDetails: PropTypes.shape({
        os: PropTypes.shape({
            name: PropTypes.string,
            version: PropTypes.string
        })
    }),
    onSummary: PropTypes.func.isRequired
}

export default DetectOperatingSystem