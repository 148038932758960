import PropTypes from "prop-types";
import { useState } from "react";

function CopyToClipboard(props) {
  const [copyStatus, setCopyStatus] = useState("");
  const summaryDivId = props.summaryDivId;
  const buttonText =
    props.buttonText ?? "Click here to copy System Check details";
  const currentWindow = props.currentWindow;
  const currentNavigator = props.currentNavigator;
  const currentDocument = props.currentDocument;

  const isClipboardAvailable =
    currentWindow.isSecureContext && "clipboard" in currentNavigator; //preferred way
  const isCopyCommandAvailable =
    currentDocument.queryCommandSupported &&
    currentDocument.queryCommandSupported("copy"); //deprecated, use as backup
  const isClipboardDataAvailable = currentWindow.clipboardData ?? false; //IE only, last resort
  const canCopy =
    isClipboardAvailable || isCopyCommandAvailable || isClipboardDataAvailable;

  const onClick = async () => {
    const summaryElement = currentDocument.getElementById(summaryDivId);

    const text = summaryElement.innerText;
    //const text = summaryElement.innerHTML; //includes HTML tags
    //const text = summaryElement.textContent; //no line breaks

    var statusText = "System Check results copied!";

    try {
      var promise = new Promise(() => {});
      if (isClipboardAvailable) {
        promise = await currentNavigator.clipboard.writeText(text);
      } else if (isCopyCommandAvailable) {
        currentDocument.execCommand("copy", false, text);
      } else if (isClipboardDataAvailable) {
        currentWindow.clipboardData.setData("Text", text);
      } else {
        //button shouldn't render if not supported, so shouldn't get here
        statusText = "Copy failed! Copy manually using CTRL+C";
      }
    } catch (e) {
      //} catch ( { name, message } ) {
      statusText = "ERROR: " + e.name + " : " + e.message;
      console.error("Failed to copy summary text", statusText);
    } finally {
      console.info("Copy summary processing completed");
    }
    setCopyStatus(statusText);

    //clear message after 3 seconds
    setTimeout(() => {
      setCopyStatus("");
    }, 3000);

    return promise;
  };

  return (
    <div>
      {canCopy ? (
        <>
          <div>
            <button onClick={onClick}>{buttonText}</button>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <b>{copyStatus}</b>
          </div>
          <div>
            <ol>
              <li>Click Copy button above.</li>
              <li>
                Paste in email or chat to share with your support
                representative.
              </li>
            </ol>
          </div>
        </>
      ) : (
        <div>
          <p className="header_instr">INSTRUCTIONS</p>
          <ol>
            <li>Hightlight the information in the box above 'INSTRUCTIONS'</li>
            <li>Copy text by pressing (CTRL + C)</li>
            <li>
              Paste it by pressing (CTRL + V) in email or chat to share with
              your support representative.
            </li>
          </ol>
          <p className="instruct">
            You can also copy and paste using your mouse cursor.
          </p>
          <ol>
            <li>Hightlight the information in the box above 'INSTRUCTIONS'</li>
            <li>Copy text by right clicking and select &quot;Copy&quot;</li>
            <li>
              Paste it by right clicking and select &quot;Paste&quot; in email
              or chat to share with your support representative.
            </li>
          </ol>
        </div>
      )}
    </div>
  );
}

CopyToClipboard.propTypes = {
  summaryDivId: PropTypes.string.isRequired,
  buttonText: PropTypes.string,
  currentWindow: PropTypes.shape({
    isSecureContext: PropTypes.bool,
    clipboardData: PropTypes.shape({
      setData: PropTypes.func,
    }),
  }),
  currentNavigator: PropTypes.shape({
    clipboard: PropTypes.shape({
      writeText: PropTypes.func,
    }),
  }),
  currentDocument: PropTypes.shape({
    queryCommandSupported: PropTypes.func,
    getElementById: PropTypes.func,
    execCommand: PropTypes.func,
  }),
};

export default CopyToClipboard;
