import { useEffect, useMemo } from "react";
import PropTypes from "prop-types";

//function DetectBrowser({ uaDetails, onSummary }) {
function DetectBrowser(props) {
  const uaDetails = props.uaDetails;
  const onSummary = props.onSummary;
  const currentNavigator = props.currentNavigator;

  const dataObject = useMemo(() => {
    return {
      browser: uaDetails.browser.name + " " + uaDetails.browser.version,
      browserName: uaDetails.browser.name,
      browserVersion: uaDetails.browser.version,
      javaEnabled: currentNavigator.javaEnabled() ? "Yes" : "No", //deprecated
    };
  }, [uaDetails, currentNavigator]);

  useEffect(() => {
    onSummary(DetectBrowser, dataObject);
  }, [onSummary, dataObject]);

  return (
    <div>
      <p className="nogap">
        Browser: {dataObject.browser}
        <br />
        Java Enabled: {dataObject.javaEnabled}
      </p>
    </div>
  );
}

DetectBrowser.propTypes = {
  uaDetails: PropTypes.shape({
    browser: PropTypes.shape({
      name: PropTypes.string,
      version: PropTypes.string,
    }),
  }).isRequired,
  onSummary: PropTypes.func.isRequired,
  currentNavigator: PropTypes.shape({
    javaEnabled: PropTypes.func,
  }),
};

export default DetectBrowser;
